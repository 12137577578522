import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/styles/reset.less'// 引入全局样式
import '@/styles/common.less'// 通用样式
import '@/styles/theme.css'// 主题样式
import '@/styles/animate.css'// 动画样式
import '@/styles/compatibility.less'// flex样式
import '../node_modules/swiper/dist/css/swiper.css'
import '@/assets/icons/index'
import '@vant/touch-emulator'


import App from './App.vue'
import store from './store'
import router from './router'
import { parseTime } from "@/utils/ruoyi";

// vant组件库适配pc端
import { CountDown } from 'vant'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import _ from 'lodash'
import VueClipBoard from 'vue-clipboard2'
import VueLuckyCanvas from '@lucky-canvas/vue'
import scroll from 'vue-seamless-scroll'//vue文字无缝滚动插
import jquery from 'jquery'

Vue.prototype._ = _  //全局导入的挂载方式
Vue.prototype.$ = jquery
Vue.prototype.parseTime = parseTime
Vue.prototype.$EventBus = new Vue();
Vue.config.productionTip = false

Vue.use(VueLuckyCanvas)
Vue.use(VueAwesomeSwiper)
Vue.use(CountDown)
Vue.use(ElementUI)
Vue.use(VueClipBoard)
Vue.use(scroll)

// 弹出框的时候滚动条隐藏和出现导致页面抖动问题
ElementUI.Dialog.props.lockScroll.default = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
