<template>
  <div id="app" :class="theme">
    <!-- <button @click="handleChangeTheme">换主题</button> -->
    <!-- 一级占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
import { integratePorts, magnifications } from '@/api/url'
export default {
  created () {
    //获取网站主题，账号回收开关，新手指南开关，在线客服开关，加入我们开关的综合接口
    if (JSON.stringify(this.$store.state.integratePortsData) !== '{}' && this.$store.state.integratePortsData.date) {

      // if (this.$store.state.integratePortsData.colors.data === 1) {
      //   this.theme = 'theme_3kf_black'
      // }
      // if (this.$store.state.integratePortsData.colors.data === 2) {
      //   this.theme = 'theme_3kf_white'
      // }
      // if (this.$store.state.integratePortsData.colors.data === 3) {
      //   this.theme = 'theme_5ww_black'
      // }
      // if (this.$store.state.integratePortsData.colors.data === 4) {
      //   this.theme = 'theme_5ww_white'
      // }

      var date1 = new Date(this.$store.state.integratePortsData.date);//!获取本地存的时间戳转换为日期对象
      var date2 = new Date(Date.now());//! 获取当前时间的时间戳转换为日期对象
      var difference = date2.getTime() - date1.getTime();//! 计算两个日期之间的差异（毫秒）
      var differenceInSeconds = difference / 1000;//! 将差异转换为秒
      var differenceInMinute = differenceInSeconds / 60;//! 将秒换成分
      console.log(differenceInMinute, '分钟,综合接口');
      if (differenceInMinute > 5) { this.integratePorts() } else {
        // if (this.$store.state.integratePortsData.colors.data === 1) {
        //   this.theme = 'theme_3kf_black'
        // }
        // if (this.$store.state.integratePortsData.colors.data === 2) {
        //   this.theme = 'theme_3kf_white'
        // }
        // if (this.$store.state.integratePortsData.colors.data === 3) {
        //   this.theme = 'theme_5ww_black'
        // }
        // if (this.$store.state.integratePortsData.colors.data === 4) {
        //   this.theme = 'theme_5ww_white'
        // }
      }
    } else { this.integratePorts() }
    // 倍率
    if (JSON.stringify(this.$store.state.magnifications) !== '{}' && this.$store.state.magnifications.date) {

      var date1 = new Date(this.$store.state.magnifications.date);//!获取本地存的时间戳转换为日期对象
      var date2 = new Date(Date.now());//! 获取当前时间的时间戳转换为日期对象
      var difference = date2.getTime() - date1.getTime();//! 计算两个日期之间的差异（毫秒）
      var differenceInSeconds = difference / 1000;//! 将差异转换为秒
      var differenceInMinute = differenceInSeconds / 60;//! 将秒换成分
      console.log(differenceInMinute, '分钟,倍率');
      if (differenceInMinute > 5) { this.magnifications() }
    } else { this.magnifications() }
  },
  mounted () {

  },
  data () {
    return {
      // theme: 'theme_3kf_black',
      theme: 'theme_3kf_white',
      // theme: 'theme_5ww_black',
      // theme: 'theme_5ww_white',
      // theme: 'theme_5ww_white',
    }
  },
  methods: {
    //获取网站主题，账号回收开关，新手指南开关，在线客服开关，加入我们开关的综合接口
    async integratePorts () {
      try {
        const res = await integratePorts()
        if (res.data.code === 200) {
          const key = 'date'
          res.data[key] = Date.now();//!存当前的时间戳
          this.$store.commit('integratePortsData', res.data)
          // if (res.data.colors.data === 1) {
          //   this.theme = 'theme_3kf_black'
          // }
          // if (res.data.colors.data === 2) {
          //   this.theme = 'theme_3kf_white'
          // }
          // if (res.data.colors.data === 3) {
          //   this.theme = 'theme_5ww_black'
          // }
          // if (res.data.colors.data === 4) {
          //   this.theme = 'theme_5ww_white'
          // }
        } else {
          this.$message({ message: res.data.msg, type: 'error', center: true });
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 倍率
    async magnifications () {
      try {
        const res = await magnifications()
        if (res.data.code === 200) {
          // console.log(res)
          const key = 'date'
          res.data[key] = Date.now();//!存当前的时间戳
          this.$store.commit('magnifications', res.data)
        } else {
          this.$message({ message: res.data.msg, type: 'error', center: true });
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  computed: {},
  watch: {},
  filters: {},
  components: {}
}
</script>

<style scoped lang='less'>
</style>
