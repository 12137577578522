import Vue from 'vue'
import VueRouter from 'vue-router'

const Layout = () => import('@/views/Layout') // 主界面
const Home = () => import('@/views/Home') // 主界面
const Help = () => import('@/views/Help') // 帮助中心
const Shop = () => import('@/views/Shop') // 积分商城
const Box = () => import('@/views/Box') // 盒子
const UserCenter = () => import('@/views/UserCenter') // 用户中心
const GameDetails = () => import('@/views/GameDetails') // 游戏详情
const NewsReport = () => import('@/views/NewsReport') // 新闻报道
const BlankPage = () => import('@/views/BlankPage') // 空白页
const BlankPage_111 = () => import('@/views/BlankPage/index_111.vue') // 空白页
const Error = () => import('@/views/Error') // 404
const Drawalotteryorraffle = () => import('@/components/Drawalotteryorraffle.vue') // 抽奖
const TurntableRaffle = () => import('@/components/TurntableRaffle.vue') // 转盘抽奖
const Word = () => import('@/components/Word.vue') // 抽奖
const CustomerService = () => import('@/views/CustomerService') // 专属客服
const Site = () => import('@/views/site.vue')


Vue.use(VueRouter)

const routes = [
  // { path: '/', redirect: '/home' },
  { path: '*', component: Error, meta: { title: 'error', name: 'error' } }, // 404
  { path: '/', component: Layout, children: [{ path: '/', component: Home, meta: { title: '首页', name: '首页' } }] },
  { path: '/site/:chapters+', name: 'Site', component: Site, }, // 游戏网站
  {
    path: '/index',
    component: Layout,
    children: [
      { path: '/', component: Home, meta: { title: '首页', name: '首页' } },
      { path: '/help', component: Help, name: 'Help', meta: { title: '帮助中心', name: '帮助中心' } },
      { path: '/shop', component: Shop, meta: { title: '积分商城', name: '积分商城' } },
      { path: '/Box', component: Box, meta: { title: '游戏盒子', name: '游戏盒子' } },
      { path: '/UserCenter', name: 'UserCenter', component: UserCenter, meta: { title: '用户中心', name: '用户中心', needLogin: true } },
      { path: '/GameDetails/:id', name: 'GameDetails', component: GameDetails, meta: { title: '游戏详情', name: '游戏详情' } },
      { path: '/NewsReport/:id', name: 'NewsReport', component: NewsReport, meta: { title: '新闻报道', name: '新闻报道' } },
      { path: '/BlankPage', component: BlankPage, meta: { title: '空白', name: '空白' } }, // 登录空白
      { path: '/BlankPage_111/index_111', component: BlankPage_111, meta: { title: '空白', name: '空白' } }, // 授权空白页
      { path: '/Drawalotteryorraffle', component: Drawalotteryorraffle, meta: { title: '抽奖中心', name: '抽奖中心' } }, // 抽奖页面
      { path: '/TurntableRaffle', component: TurntableRaffle, meta: { title: '抽奖中心', name: '抽奖中心' } }, // 转盘抽奖页面
      { path: '/Word', component: Word, meta: { title: '用户注册协议', name: '用户注册协议' } }, // 用户注册协议
      { path: '/CustomerService', component: CustomerService, meta: { title: '专属客服', name: '专属客服' } }, // 专属客服
    ]
  }
]

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title === '首页') {
    const title = window.localStorage.getItem('title1')
    document.title = title
  } else if (to.meta.title === '新闻报道') {
    document.title = ''
  } else {
    const title1 = window.localStorage.getItem('title2')
    document.title = to.meta.title + ' -' + title1
  }
  const token = localStorage.getItem('token')
  if (to.meta.needLogin) { // 判断该路由是否需要登录权限
    if (token) { // 判断是否已经登录
      next()
    } else {
      next({ path: '/' }) // 跳转到登录页
      Vue.prototype.$message({ message: '登录即可享受更多福利', type: 'warning', center: true })
    }
  }
  next()
})
// 防止跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}
export default router
