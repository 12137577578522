import http from '@/utils/http'
const register = '/register'
const login = '/login'
const captchaimage = '/captchaImage'
const category_list = '/website/game/category/list'
const newest_list = '/website/game/newest/list'
const filter_list = '/website/game/filter/list'
const staff = '/website/staff/list'
const article_category = '/website/article/category/list'
const article_list = '/website/article/list'
const article_search = '/website/article/search'
const carousel = '/website/carousel/list'
const WX = '/wechat/image/url'
const polling = '/wechat/login/status'
const game_list = '/website/game/info/list'
const user = '/getInfo'
const nick_name = '/system/user/profile/nickname'
const bg = '/website/carousel/bg/list'
const unboundPhones = '/system/user/unboundPhones'
const boundPhones = '/system/user/boundPhones'
const selectIdCardverifyByUserId = '/member/idCardAudit/selectIdCardverifyByUserId'
const checknow = '/member/idCardAudit/checknow'
const qrcode = '/system/user/qrcode'
const status = '/wechat/bind/status'
const system_message = '/system/message/list'
const game_message = '/system/message/game/list'
const ratio = '/payment/product/list'
const getPhoneCaptcha = '/getPhoneCaptcha'
const getPhoneCaptchajbs = '/phone/solution'
const updateUserPassword = '/system/user/updateUserPassword'
const my_list = '/version/collect/my/list'
const info = '/system/user/profile/bind/info'
const PKcion = '/external/order/PkCoin/list'
const menUserList = '/external/order/score/menUserList'
const exchange = 'external/order/exchange/menUserList'
const exchange_men = '/external/order/exchange/menUserList'
const selectSginins = '/selectSginin'
const PayQrcode = '/pay/sm/qrcode'
const payOrder = '/order/pay/time/list'
const gainPK = '/website/getPkCoin/selectPkCoin'
const loginLocation = '/system/user/loginLocation'
const orderExchange = '/order/exchange/list'
const Signin = '/insertSignin'
const getCaptcha = '/phone/captcha'
const phoneVerify = '/phone/captcha/verify'
const phoneUpdatepassword = '/system/user/updatepassword'
const weChatUpdatepassword = '/system/user/profile/openid/set/pwd'
const paystatus = '/pay/sm/status'
const userProfile = '/system/user/profile'
const updateMemDel = '/system/message/updateMemDel'
const getMessageSum = '/system/message/messageSum'
const gameDetails = '/website/game'
const gameCollect = '/version/collect'
const postGameCollect = '/version/collect'
const versionList = '/part/list/version'
const cashBenefits = '/cashBenefits'
const exchangeOrderStatus = '/exchange/response'
const renewList = '/order/pay/order/record'
const orderScorelist = '/order/score/list'
const PKorder = '/order/pk/coin/list'
const articleDetails = '/website/article/siteVisible/details'
const getpkScoreBalance = '/system/user/longUser/pkScoreBalance'
const exTipText = '/system/user/setting/exTip'
const getexchange = '/system/user/setting/exchange'
const Messageread = '/system/message/read'
const categoryArticles = '/website/article/category/articles'
const hotArticleList = '/website/hot/article/list'
const prompt = '/system/user/setting/prompt'
const GetTopUpAmount = '/system/user/setting/topUp'
const QueryWebsiteInformation = '/system/user/setting/website'
const boxInstallHref = '/system/user/setting/boxInstallHref'
const getDailyGift = '/order/daily/gift/getDailyGift'
const getGrowPicture = '/system/user/setting/getGrowPicture'
const getIP = '/check/ip/first'
const getDomain = '/partner/game/website/domain'
const logout = '/logout'
const BagReceivingStatus = '/order/daily/gift/response'
const getLogo = '/system/user/setting/logo'
const boundPhoneS = '/system/user/boundPhones'
const firstCharge = '/system/user/setting/give/tips'
const memReissue = '/reissue/mem/reissue'
const memResponse = '/reissue/mem/response'
const sign = '/wechat/image/sign'
const signstate = '/in/game/member/sign'
const pkCoin = '/wechat/image/pkCoin'
const conversionCode = '/wechat/member/conversion'
const WechatMmessage = '/external/wechat/unbind/tips'
const Unbindwechat = '/wechat/unbind'
const gameRecord = '/game/record'
const assessment = '/game/value'
const recycle = '/game/value/logout'
const zhhs_list = '/game/value/name'
const Combinedaccount = '/external/merge/member'
const giftTip = '/system/user/setting/gift/tip'
const paymentLimit = '/external/member/payment/limit'
const Mobilephoneswitch = '/external/member/registerInfo/phone/switch'
const BindingGift = '/system/user/setting/givePkCoin'
const Memberloginoption = '/external/member/loginInfo/option'
const staffPicture = '/system/user/setting/get/unique/staff/picture'
const exclusiveList = '/website/staff/exclusive/list'
const exchangePop = '/exchange/pop'
const photoactivity = '/site/homeActivity/list'
const exchangemessage = '/website/game/exchange/message'
const migrationMessage = '/external/member/migration/user/message'
const migration = '/external/member/migration'
const IndividualActivity = '/site/homeActivity/centre/list'
const integratePort = '/external/home/page/integrate'
const drawUserNUM = '/external/draw/activity/user/message/num'
const drawUsermoney = '/external/draw/activity/money'
const drawUserrule = '/external/draw/activity/rule'
const magnification = '/external/home/page/member/ratio'
const systemQuestionnaire = '/system/questionnaire'
const getGuestionnaire = '/system/questionnaire'
const announcementNotice = '/external/announcement/notice?displayPosition=2'
const operateMemberGift = '/gift/operate/member/operate'
const giftResponse = '/gift/operate/response'

// 网关返回会员使用礼包消息
export const giftResponses = (data) => { return http({ method: 'POST', url: giftResponse, data }) }
// 礼包物品使用
export const operateMemberGifts = (data) => { return http({ method: 'POST', url: operateMemberGift, data }) }
//会员公告
export const announcementNotices = () => { return http({ method: 'GET', url: announcementNotice }) }
//问卷填写情况
export const getGuestionnaires = () => { return http({ method: 'GET', url: getGuestionnaire }) }
// 问卷提交
export const systemQuestionnaires = (data) => { return http({ method: 'POST', url: systemQuestionnaire, data }) }
//网站倍数
export const magnifications = () => { return http({ method: 'GET', url: magnification }) }
//抽奖规则
export const drawUserrules = () => { return http({ method: 'GET', url: drawUserrule }) }
//抽奖金额
export const drawUsermoneys = () => { return http({ method: 'GET', url: drawUsermoney }) }
//获取抽奖会员信息抽奖次数
export const drawUserNUMS = () => { return http({ method: 'GET', url: drawUserNUM }) }
//获取网站主题，账号回收开关，新手指南开关，在线客服开关，加入我们开关的综合接口
export const integratePorts = () => { return http({ method: 'GET', url: integratePort }) }
// 迁移会员
export const migrations = (data) => { return http({ method: 'POST', url: migration, data }) }
// 获取迁移会员信息
export const migrationMessages = () => { return http({ method: 'GET', url: migrationMessage }) }
// 获取个人中心游戏兑换的游戏列表
export const exchangemessages = (params) => { return http({ method: 'GET', url: exchangemessage, params }) }
// 会员中心是否显示兑换弹窗
export function exchangePops () { return http({ url: exchangePop, method: 'GET' }) }
// 专属客服二维码
export function exclusiveLists () { return http({ url: exclusiveList, method: 'GET' }) }
// 专属客服背景
export function staffPictures () { return http({ url: staffPicture, method: 'GET' }) }
// 判断会员是否过期
export function paymentLimits () { return http({ url: paymentLimit, method: 'GET' }) }
// 礼包领取提示
export function giftTips () { return http({ url: giftTip, method: 'GET' }) }
// 账号合并
export function Combinedaccounts (data) { return http({ url: Combinedaccount, method: 'POST', data: data }) }
// 获取可以账号回收的游戏列表
export const zhhs_lists = (params) => { return http({ method: 'GET', url: zhhs_list, params }) }
// 账号回收
export const recycles = (data) => { return http({ method: 'PUT', url: recycle, data }) }
// 查询游戏账号价值
export const assessments = (params) => { return http({ method: 'GET', url: assessment + '/' + params }) }
// 查询真实游戏分组
export function gameRecords (params) { return http({ url: gameRecord, method: 'GET', params }) }
// 微信解绑提示信息
export function WechatMmessages () { return http({ url: WechatMmessage, method: 'GET' }) }
// 解绑微信
export function UnbindWechats () { return http({ url: Unbindwechat, method: 'GET' }) }
//获取兑换二维码状态
export const conversionCodes = (params) => { return http({ method: 'GET', url: conversionCode, params: params }) }
//PK币扫码兑换
export const pkCoins = (data) => { return http({ method: 'POST', url: pkCoin, data }) }
//获取签到二维码
export const SignS = () => { return http({ method: 'GET', url: sign }) }
//获取签到二维码状态
export const SignState = (params) => { return http({ method: 'GET', url: signstate, params: params }) }
// 补发订单返回状态
export const memResponses = (data) => { return http({ method: 'POST', url: memResponse, data }) }
// 会员自主补发
export const memReissues = (data) => { return http({ method: 'POST', url: memReissue, data }) }
// 个人中心赠送提示
export const firstCharges = () => { return http({ method: 'GET', url: firstCharge }) }
// 绑定手机号
export const boundPhoneSs = (data) => { return http({ method: 'PUT', url: boundPhoneS, data }) }
// 获取首页logo
export const getLogos = () => { return http({ method: 'GET', url: getLogo }) }
//每日礼包领取状态
export function BagReceivingStatusS (data) { return http({ url: BagReceivingStatus, method: 'POST', data: data }) }
// 退出登录
export const logouts = (data) => { return http({ method: 'POST', url: logout, data }) }
// 获取游戏域名
export const getDomains = () => { return http({ method: 'GET', url: getDomain }) }
// 获取IP使用情况
export const getIPs = () => { return http({ method: 'GET', url: getIP }) }
// 获取成长福利背景图
export const getGrowPictures = () => { return http({ method: 'GET', url: getGrowPicture }) }
// 领取每日礼包
export function getDailyGifts (data) { return http({ url: getDailyGift, method: 'POST', data }) }
// 获取游戏盒子链接
export const boxInstallHrefs = () => { return http({ method: 'GET', url: boxInstallHref }) }
// 查询网站信息
export const QueryWebsiteInformations = () => { return http({ method: 'GET', url: QueryWebsiteInformation }) }
// 查询充值金额
export const GetTopUpAmounts = () => { return http({ method: 'GET', url: GetTopUpAmount }) }
// 获取充值提示的文本
export const prompts = () => { return http({ method: 'GET', url: prompt }) }
// 帮助中心文章分类和文章内容
export const hotArticleLists = () => { return http({ method: 'GET', url: hotArticleList }) }
// 帮助中心文章分类和文章内容
export const categoryArticless = () => { return http({ method: 'GET', url: categoryArticles }) }
// 一键已读
export const Messagereads = () => { return http({ method: 'PUT', url: Messageread }) }
// 获取兑换金额
export const getexchanges = () => { return http({ method: 'GET', url: getexchange }) }
// 兑换提示文本
export const exTipTexts = () => { return http({ method: 'GET', url: exTipText }) }
// 获取当前登录用户的积分，平台币，PK币
export const getpkScoreBalances = () => { return http({ method: 'GET', url: getpkScoreBalance }) }
// 文章详情
export const articleDetailss = (params) => { return http({ method: 'GET', url: articleDetails + '/' + params }) }
// pk币兑换订单
export const PKorders = (params) => { return http({ method: 'GET', url: PKorder, params }) }
// 积分兑换订单查询
// export const orderScorelists = (params) => { return http({ method: 'GET', url: orderScorelist, params }) }
// 会员续费获取记录
export const renewLists = (params) => { return http({ method: 'GET', url: renewList, params }) }
// 游戏兑换订单返回状态
export const exchangeOrderStatuss = (data) => { return http({ method: 'POST', url: exchangeOrderStatus, data }) }
// 平台币游戏兑换
export const cashBenefitss = (data) => { return http({ method: 'POST', url: cashBenefits, data }) }
// 查询游戏收藏数量
export const versionLists = (params) => { return http({ method: 'GET', url: versionList + '/' + params }) }
// 游戏收藏
export const postGameCollects = (data) => { return http({ method: 'POST', url: postGameCollect, data }) }
// 查询游戏收藏数量
export const gameCollects = (params) => { return http({ method: 'GET', url: gameCollect + '/' + params }) }
// 查询游戏详情
export const gameDetailss = (params) => { return http({ method: 'GET', url: gameDetails + '/' + params }) }
// 查询会员未读消息
export const getMessageSums = () => { return http({ method: 'GET', url: getMessageSum }) }
// 修改删除我的消息标识
export const dateMemDels = (data) => { return http({ method: 'PUT', url: updateMemDel, data }) }
// 修改个人资料
export const userProfiles = (data) => { return http({ method: 'PUT', url: userProfile, data }) }
// 获取支付状态（轮询）
export const paystatuss = (data) => { return http({ method: 'POST', url: paystatus, data }) }
// 忘记密码微信修改密码
export const weChatUpdatepasswords = (data) => { return http({ method: 'POST', url: weChatUpdatepassword, data }) }
// 忘记密码手机号修改密码
export const phoneUpdatepasswords = (data) => { return http({ method: 'PUT', url: phoneUpdatepassword, data }) }
// 手机号验证码验证
export const phoneVerifys = (data) => { return http({ method: 'POST', url: phoneVerify, data }) }
// 直接获取手机验证码
export const getCaptchas = (data) => { return http({ method: 'POST', url: getCaptcha, data }) }
// 会员签到
export const Signins = () => { return http({ method: 'PUT', url: Signin }) }
// 登陆地址记录查询（分页)
export const loginLocations = (params) => { return http({ method: 'GET', url: loginLocation, params: params }) }
// pk获取订单查询（分页）
export const gainPKs = (value) => { return http({ method: 'GET', url: gainPK, params: value }) }
// 用户充值订单查询（分页）
export const payOrders = (value) => { return http({ method: 'GET', url: payOrder, params: value }) }
// 会员平台币兑换订单（分页）
export const orderExchanges = (value) => { return http({ method: 'GET', url: orderExchange, params: value }) }
// 查询用户签到天数
export const selectSginin = () => { return http({ method: 'GET', url: selectSginins }) }
// 会员平台币兑换订单（分页）
export const exchange_mens = () => { return http({ method: 'GET', url: exchange_men }) }
// 会员平台币兑换订单
export const exchanges = () => { return http({ method: 'GET', url: exchange }) }
// 会员查询积分订单（分页）
// export const menUserLists = () => { return http({ method: 'GET', url: menUserList }) }
// pk币兑换订单
export const PKcions = value => { return http({ method: 'GET', url: PKcion, params: value }) }
// 用户昵称修改
export const nickname = value => { return http({ method: 'POST', url: nick_name, data: { nickname: value } }) }
// 用户信息获取
export const users = () => { return http({ method: 'GET', url: user }) }
// 轮播图
export const carousels = () => { return http({ method: 'GET', url: carousel }) }
// 文章列表（分类分页）
export const article_searchs = value => { return http({ method: 'GET', url: article_search, params: { pageNum: value.pageNum, pageSize: value.pageSize } }) }
// 文章列表
export const article_lists = () => { return http({ method: 'GET', url: article_list }) }
// 文章分类列表
export const article_categorys = () => { return http({ method: 'GET', url: article_category }) }
// 客服二维码
export const staffs = () => { return http({ method: 'GET', url: staff }) }
// 分类筛选所有游戏列表
export const filter_lists = value => { return http({ method: 'GET', url: filter_list, params: value }) }
// 最新游戏列表
export const newest_lists = value => { return http({ method: 'GET', url: newest_list, params: { pageNum: value.pageNum, pageSize: value.pageSize } }) }
// 游戏分类
export const category_lists = value => { return http({ method: 'GET', url: category_list, params: { pageNum: value.pageNum, pageSize: value.pageSize } }) }
// 获取手机验证码(绑定)
export const getPhoneCaptchas = value => { return http({ method: 'POST', url: getPhoneCaptcha, data: value }) }
// 获取手机验证码(解绑)
export const getPhoneCaptchajb = value => { return http({ method: 'POST', url: getPhoneCaptchajbs, data: value }) }
// 验证码
export const captchaImage = () => { return http({ method: 'GET', url: captchaimage }) }
// 会员登录注册
export const logins = (value) => { return http({ method: 'POST', url: login, data: value }) }
// 注册方法
export function register1 (data) { return http({ url: register, method: 'POST', data: data }) }
// 微信二维码
export const WX_login = () => { return http({ method: 'GET', url: WX }) }
// 微信轮询
export const WX_polling = value => { return http({ method: 'POST', url: polling, data: { sceneStr: value } }) }
// 游戏列表
export const game_lists = value => { return http({ method: 'GET', url: game_list, params: value }) }
// 获取用户绑定手机号、微信信息
export const infos = value => { return http({ method: 'GET', url: info, params: value }) }
// 背景
export const bgs = () => { return http({ method: 'GET', url: bg }) }
// 解绑手机号
export const unboundPhoness = (data) => { return http({ method: 'PUT', url: unboundPhones, data }) }
// 绑定手机号
export const boundPhoness = value => { return http({ method: 'PUT', url: boundPhones, data: value }) }
// 实名认证查询
export const selectIdCardverifyByUserIds = () => { return http({ method: 'POST', url: selectIdCardverifyByUserId }) }
// 实名认证
export const checknows = value => { return http({ method: 'POST', url: checknow, data: value }) }
// 绑定微信二维码
export const qrcodes = () => { return http({ method: 'GET', url: qrcode }) }
// 绑定微信二维码轮询
export const statuss = () => { return http({ method: 'GET', url: status }) }
// 查询系统消息
export const system_messages = value => { return http({ method: 'GET', url: system_message, params: value }) }
// 查询游戏消息
export const game_messages = value => { return http({ method: 'GET', url: game_message, params: value }) }
// 查询收藏
export const my_lists = value => { return http({ method: 'GET', url: my_list, params: value }) }
// 获取支付产品、充值赠送比例
export const ratios = (value) => { return http({ method: 'GET', url: ratio, params: value }) }
// 获取充值二维码
export const PayQrcodes = (data) => { return http({ method: 'POST', url: PayQrcode, data }) }
// 头像
export function uploadAvatar (data) { return http({ url: '/system/user/profile/avatar', method: 'POST', data: data }) }
// 修改密码
export function updateUserPasswords (data) { return http({ url: updateUserPassword, method: 'PUT', data: data }) }
// 手机注册开关
export const Mobilephoneswitchs = () => { return http({ method: 'GET', url: Mobilephoneswitch }) }
// 绑定赠送pk币金额
export const BindingGifts = () => { return http({ method: 'GET', url: BindingGift }) }
// 会员登录选项开关
export const Memberloginoptions = () => { return http({ method: 'GET', url: Memberloginoption }) }
// 活动照片信息
export const photoactivitys = () => { return http({ method: 'GET', url: photoactivity }) }
// 个人中心活动
export const individualActivitys = () => { return http({ method: 'GET', url: IndividualActivity }) }